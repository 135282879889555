// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Profil-module--ButtonWrapper--GbrcA";
export var ColoredTextLink = "Profil-module--ColoredTextLink--untJk";
export var DeleteAccountWrapper = "Profil-module--DeleteAccountWrapper--hXDJs";
export var ImageWrapper = "Profil-module--ImageWrapper--Symaa";
export var InformationContent = "Profil-module--InformationContent--P-N9Q";
export var InformationTitle = "Profil-module--InformationTitle--WmBXj";
export var InformationsContent = "Profil-module--InformationsContent--qHDJ2";
export var InformationsWrapper = "Profil-module--InformationsWrapper--kNF5r";
export var LogOutWrapper = "Profil-module--LogOutWrapper--lx93o";
export var NoImageWrapper = "Profil-module--NoImageWrapper--3Y-sQ";
export var Rounded = "Profil-module--Rounded--KIe3Y";
export var RowWrapper = "Profil-module--RowWrapper--KFoSa";
export var Spacer = "Profil-module--Spacer--jWKZM";
export var UsedOffersWrapper = "Profil-module--UsedOffersWrapper--IHcID";
export var Wrapper = "Profil-module--Wrapper--Ci7+j";