import React, { useState } from "react"
import {
  Wrapper,
  ButtonWrapper,
  RowWrapper,
  Spacer,
  ColoredTextLink,

  /* PersonalInformations */
  InformationsWrapper,
  InformationsContent,
  ImageWrapper,
  Rounded,
  NoImageWrapper,
  InformationTitle,
  InformationContent,

  /* UsedOffersWrapper */
  UsedOffersWrapper,

  /* LogOutWrapper */
  LogOutWrapper,

  /* DeleteAccountWrapper */
  DeleteAccountWrapper,
} from "./Profil.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import {
  PaddingTop20,
  PaddingTop30,
  PaddingTop50,
} from "./../../../css/Padding.module.css"
import Title from "./../../../components/Title"
import Button from "./../../../components/Button"
import { classNames } from "./../../../helpers/classNames"
import OfferCard from "./../../../components/OfferCard"
import AddImage from "./../../../res/add_image.png"
import Popup from "./../../../components/Popup"
import { logOut } from "./../../../auth"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"
const isBrowser = typeof window !== "undefined"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const DeleteAccount = ({
  user,
  setSuccessSnackbarIsOpened,
  setErrorSnackbarIsOpened,
}) => {
  const [deletePopupIsOpened, setDeletePopupIsOpened] = useState(false)

  const toggleDeletePopup = () => {
    setDeletePopupIsOpened(!deletePopupIsOpened)
  }

  const handleDeleteAccount = () => {
    toggleDeletePopup()
    firebase
      .firestore()
      .collection("users")
      .doc(user.id)
      .delete()
      .then(() => {
        firebase
          .auth()
          .currentUser.delete()
          .then(() => {
            setSuccessSnackbarIsOpened(true)
            if (isBrowser) {
              window.location.href = "/"
            }
          })
          .catch(error => {
            //console.log("error: ", error)
            setErrorSnackbarIsOpened(true)
          })
      })
      .catch(error => {
        //console.log("error: ", error)
        setErrorSnackbarIsOpened(true)
      })
  }

  return (
    <div className={DeleteAccountWrapper}>
      <Title title={"Supprimer mon compte"} />
      <div className={classNames([ButtonWrapper, PaddingTop30])}>
        <Button
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          alt=""
          onClick={() => toggleDeletePopup()}
        >
          Supprimer mon compte
        </Button>
      </div>
      {deletePopupIsOpened && (
        <Popup
          title={"Attention !"}
          onClose={() => toggleDeletePopup()}
          question={"Es-tu sûr de vouloir supprimer ton compte ?"}
          onCancel={() => toggleDeletePopup()}
          onOk={() => handleDeleteAccount()}
        />
      )}
    </div>
  )
}

const LogOut = () => {
  const handleLogOutButton = () => {
    logOut()
  }

  return (
    <div className={LogOutWrapper}>
      <Title title={"Me déconnecter"} />
      <div className={classNames([ButtonWrapper, PaddingTop30])}>
        <Button
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          alt=""
          onClick={() => handleLogOutButton()}
        >
          Me déconnecter
        </Button>
      </div>
    </div>
  )
}

const UsedOffers = ({ usedOffers }) => {
  const handleSeeMoreUsedOffersButton = () => {
    if (isBrowser) {
      window.location.href = "/utilisateurs/offres-utilisees"
    }
  }

  const getFormatedDate = timestamp => {
    return Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp / 1000)
  }

  if (usedOffers !== undefined && usedOffers && usedOffers.length > 0) {
    return (
      <div className={UsedOffersWrapper}>
        <Title title={"Offres utilisées"} />
        <div style={{ paddingTop: "30px" }} className={RowWrapper}>
          {usedOffers.map((usedOffer, idx = 0) =>
            ++idx <= usedOffers.length && idx <= 3 ? (
              <>
                <OfferCard
                  image={usedOffer.mapValue.fields.image.stringValue}
                  date={getFormatedDate(
                    usedOffer.mapValue.fields.date.integerValue
                  )}
                  category={usedOffer.mapValue.fields.category.stringValue}
                  shopName={usedOffer.mapValue.fields.shopId.stringValue}
                  title={usedOffer.mapValue.fields.title.stringValue}
                />
                {idx === 3 || idx === usedOffers.length ? null : (
                  <div className={Spacer}></div>
                )}
              </>
            ) : null
          )}
        </div>
        <div
          style={{
            paddingTop: "30px",
            paddingLeft: "calc((80vw / 2) - 100px)",
          }}
          className={classNames([ButtonWrapper, PaddingTop30])}
        >
          <Button alt="" onClick={() => handleSeeMoreUsedOffersButton()}>
            Voir plus
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const PersonalInformations = ({ user }) => {
  const getFormatedDate = () => {
    return Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(user.birthDate / 1000)
  }

  const getTransformedPassword = () => {
    let idx = 0
    let result = ""

    while (idx < user.password.length) {
      result += "*"
      idx++
    }
    return result
  }

  return (
    <div className={InformationsWrapper}>
      <Title title={"Mes informations"} />
      <div className={InformationsContent}>
        <div className={ImageWrapper}>
          <label htmlFor="upload-button">
            {user.refImage ? (
              <img src={user.refImage} className={Rounded} alt="profile" />
            ) : (
              <div className={NoImageWrapper}>
                <img
                  src={AddImage}
                  alt="profile"
                  width="75"
                  height="75"
                  style={{ paddingLeft: "37px", paddingTop: "37px" }}
                />
              </div>
            )}
          </label>
          {/*<input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />*/}
        </div>
        <div className={classNames([RowWrapper, PaddingTop50])}>
          <p className={InformationTitle}>Prénom</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.firstName}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Nom de famille</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.lastName}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Genre</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.gender}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Date de naissance</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{getFormatedDate()}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Code postal</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.postalCode}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Ville</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.city}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Numéro de téléphone</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.phoneNumber}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Email</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{user.email}</p>
        </div>
        <div className={classNames([RowWrapper, PaddingTop20])}>
          <p className={InformationTitle}>Mot de passe</p>
          <div className={Spacer}></div>
          <p className={InformationContent}>{getTransformedPassword()}</p>
        </div>
        <div className={PaddingTop30}>
          <a
            href="/utilisateurs/profil/modifier-mot-de-passe"
            className={ColoredTextLink}
          >
            Modifier mon mot de passe
          </a>
        </div>
      </div>
    </div>
  )
}

const Profil = ({ user, setUser }) => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Profil</h1>
      {user ? (
        <>
          <PersonalInformations user={user} />
          <UsedOffers usedOffers={user.usedOffers} />
        </>
      ) : null}
      <LogOut />
      <DeleteAccount
        user={user}
        setSuccessSnackbarIsOpened={setSuccessSnackbarIsOpened}
        setErrorSnackbarIsOpened={setErrorSnackbarIsOpened}
      />
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as ajouté un nouveau commerce !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Profil
